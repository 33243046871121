import { Riple } from "react-loading-indicators";
import ExcavatorsHourlyResult from "./ExcavatorsHourlyResult/ExcavatorsHourlyResult";
import ExcavatorTruckHourlyResult from "./ExcavatorTruckHourlyResult/ExcavatorTruckHourlyResult";
import { Status } from "pages/Dashboard/constant";

const statusPriority = {
   [Status.ASSIGNED]: 1,
   [Status.REASSIGNED]: 1,
   [Status.UNASSIGNED]: 2,
   [Status.UNSCHEDULED]: 3,
   [Status.REMOVED]: 4,
};

const HourlyResult = ({ isLoading, exHourlyResultData, shiftType }) => {
   if (isLoading)
      return (
         <div
            style={{ height: "100%" }}
            className="d-flex align-items-center justify-content-center"
         >
            <Riple color="#317bcc" size="medium" text="" textColor="" />
         </div>
      );
   if (
      !exHourlyResultData.exHourlyResult?.[0]?.truckAssignments ||
      !exHourlyResultData
   ) {
      return (
         <div
            style={{ color: "#99A0AC", flexGrow: 1 }}
            className="d-flex px-5 w-100 align-items-center justify-content-center"
         >
            <span className="mb-3">No records to display</span>
         </div>
      );
   }
   return (
      <div className="px-5">
         <ExcavatorsHourlyResult
            shiftType={shiftType}
            exHourlyResultData={exHourlyResultData}
         />
         <ExcavatorTruckHourlyResult
            shiftType={shiftType}
            shiftStatus={exHourlyResultData?.shiftStatus}
            productiveHours={
               exHourlyResultData?.exHourlyResult?.[0]?.productiveHours
            }
            exHourlyTruckResult={exHourlyResultData.exHourlyResult?.[0]?.truckAssignments.sort(
               (a, b) => {
                  // Sort by status priority
                  const statusDiff =
                     statusPriority[a.status] - statusPriority[b.status];
                  if (statusDiff !== 0) return statusDiff;

                  // Secondary sorting within ASSIGNED status: actualLoads vs. targetLoads
                  if (
                     statusPriority[a.status] == 1 &&
                     statusPriority[a.status] == 1
                  ) {
                     return a.actualLoads < a.targetLoads ? ((b.actualLoads < b.targetLoads) ? 0 : -1) : (b.actualLoads < b.targetLoads ? 1 : 0);
                  }

                  return 0;
               }
            )}
         />
      </div>
   );
};

export default HourlyResult;
