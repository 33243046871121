import React from 'react';
import './style.css';
import AuthLayout from '../../components/AuthLayout';
import { DashboardContextProvider } from './Context/DashboardContext';
import DashboardComponent from './DashboardComponent';
import { Helmet } from 'react-helmet';
export default function Dashboard() {
  return (
    <AuthLayout pageName="Dump Cycle Tracker">
      <Helmet>
        <title>Dump Cycle Tracker | iMOS</title>,
      </Helmet>
      <DashboardContextProvider>
        <DashboardComponent />
      </DashboardContextProvider>
    </AuthLayout>
  );
}
