import Flyer from "pages/ShiftNotes/Component/Flyers/Flyer";
import { useEffect, useRef, useState } from "react";
import { CallWithAuth } from "action/apiActions";
import { toast } from "react-toastify";
import {

  formateDataForSaving,
} from "pages/DelayAndActivity/utils/helper";

import EditShiftNotesComponent from "./EditShiftNotesComponent";
import { useShiftNotesContext } from "pages/ShiftNotes/Context/context";

const EditShiftNotes = ({ data: originalData }) => {
  const { openFlyer, setOpenFlyer, equipmentList,setRefreshPage , refreshPage } =
    useShiftNotesContext();

  const [formData, setFormData] = useState({
    noteType: originalData.noteType,
    assets: originalData.assets?.map((asset) => equipmentList?.find((eq) => eq.label == asset)).filter(Boolean),
    note: originalData.note,
  });

  const [showError, setShowError] = useState(false);


  const isFormValid = () => {
    if ( formData.noteType !== "Generic" && !formData.assets?.length) {
      setShowError(true);
      return false;
    }

      const isNotesValid = (
        formData.noteType &&
        formData.note
      ) ? true : false;

    if (!isNotesValid) {
      setShowError(true);
      return false;
    }

    setShowError(false);
    return true;
  };

  const saveChanges = async () => {
    //Validate form data.
    if (!isFormValid()) return;

    // Validate delays first
    const formattedDelay = formateDataForSaving(formData);

    const validatedData = {
      ...formattedDelay,
    };

    const reqBody = {
      shiftNoteId: originalData.shiftNoteId,
    };

    // Check for each field before adding to reqBody
    if (
      validatedData.noteType &&
      validatedData.noteType !== originalData.noteType
    ) {
      reqBody.noteType = validatedData.noteType;
    }

    if (
      validatedData.note &&
      validatedData.note !== originalData.note
    ) {
      reqBody.note = validatedData.note;
    }

    if (
      validatedData.assets &&
      validatedData.assets !== originalData.assets
    ) {
      reqBody.assets = validatedData.assets;
    }

    // Check if reqBody has any changes to send
    if (Object.keys(reqBody).length === 1) {
      // Only contains delayId
      toast.error("No changes detected to save.");
      return; // Exit if no changes
    }

    try {
      const response = await CallWithAuth("PUT", "/api/shiftNotes", reqBody);
      if (response?.res?.status === 202) {
        toast.success("Note edited successfully!");
        setOpenFlyer("");
        setRefreshPage(!refreshPage)
      } else {
        throw new Error("Failed to edit note");
      }
    } catch (error) {
      toast.error("An error occurred while editing note. Please try again.");
    }
  };

  return (
    <>
      {openFlyer === "NOTES_EDIT" && (
        <Flyer
          isOpen={openFlyer === "NOTES_EDIT"}
          onClose={() => setOpenFlyer("")}
          title={"Edit Notes"}
          containerStyle={{
            zIndex: "100",
            right: 0,
            bottom: 0,
            top: "unset",
            height: "calc(100vh - 11.6rem)",
          }}
          headerClassName={"pt-3"}
          headerHeight={"60px"}
          hideBorderBottom={true}
          tabs={[
            {
              title: "",
              rightActionButtons: [
                {
                  label: "Save Changes",
                  onClick: async () => {
                    await saveChanges();
                  },
                },
              ],

              component: (
                <EditShiftNotesComponent
                  showError={showError}
                  setFormData={setFormData}
                  formData={formData}
                />
              ),
            },
          ]}
        />
      )}
    </>
  );
};

export default EditShiftNotes;
