import TruckProgressBar from "pages/Dashboard/components/AssetProgressBar/AssetProgressBar";
import "../PerformanceSummary/Performance.css";
import AssetBadge from "pages/Dashboard/components/AssetBadge";
import AssetDot from "pages/Dashboard/components/AssetDot";
import PerformanceIcon from "../../../../../assets/images/Dashboard/Performance.svg";
import { MdOutlineSignalCellularAlt } from "react-icons/md";

function Performance({ data }) {
  const toTarget = data?.shiftTargetBCM - data?.shiftActualBCM;

  const appearance = {};

  appearance.bgColor = "#FF5D5D";
  appearance.color = "#FFFFFF";
  if (data?.shiftActualBCM >= data?.shiftTargetBCM) {
    appearance.bgColor = "#8DDA20";
    appearance.color = "#000000";
  }

  return (
    <div
      style={{
        padding: "20px 30px",
        height: 250,
        boxShadow: "0px 0px 45px #A3A3A31F",
      }}
      className=" d-flex justify-content-between align-items-center card-performance w-50"
    >
      <div
        style={{ width: "calc(100% - 250px)" }}
        className="d-flex flex-column justify-content-between h-100 pr-5"
      >
        <div className="w-100 performance-card-header">
          <div style={{ font: "normal normal 600 14px/19px Open Sans" }} className="pb-3 d-flex align-items-center">
            <img height={23} src={PerformanceIcon} alt='PerformanceIcon' />
            <div className="ml-2">Performance</div>
          </div>
        </div>

        <div className=" w-100 performance-section ">
          <div className="d-flex justify-content-between align-items-center pb-2">
            <div style={{ font: "normal normal 600 14px/19px Open Sans" }}>
              Shift Total
            </div>
            <div style={{ font: "normal normal 600 14px/19px Open Sans" }}>
              {data?.shiftActualBCM ?? '-'}{" "}
              <span
                style={{ font: "normal normal normal 14px/19px Open Sans" }}
              >
                / {data?.shiftTargetBCM ?? '-'} BCM
              </span>
            </div>
          </div>

          <TruckProgressBar
            width={"100%"}
            value={data?.shiftActualBCM ?? 0}
            max={data?.shiftTargetBCM ?? 1}
            arrowDirection="right"
            shadowColor={data?.shiftTargetBCM ? true : false}
          />

          <div className="d-flex justify-content-between align-items-center mt-3">
            <div className="performance-total-label">
              <AssetDot bgColor={data?.shiftTargetBCM != undefined ? appearance.bgColor : "#A2A2A2"} />
              Total BCM
            </div>
            <AssetBadge
              value={data?.shiftActualBCM !== undefined ? `${data?.shiftActualBCM} BCM` : " - "}
              color={appearance.color}
              bgColor={data?.shiftActualBCM !== undefined ? appearance.bgColor : "#A2A2A2"}
              font={"normal normal 600 12px/17px Open Sans"}
              className={data?.shiftActualBCM !== undefined ? "" : "px-3 py-1"}
            />
          </div>

          <div className="d-flex align-items-center justify-content-between mt-3 ">
            <div className="performance-total-label">
              {/* <span className="grey-dot"></span>  */}
              <AssetDot bgColor={"#DEDEDE"} />
              To Target
            </div>
            {/* <div className="badge-target px-3 py-1">3000 BCM</div> */}
            <AssetBadge
              value={toTarget ? `${toTarget} BCM` : ' - '}
              color={"#000000"}
              bgColor={"#dedede"}
              font={"normal normal 600 12px/17px Open Sans"}
              className={data?.shiftActualBCM !== undefined ? "" : "px-3 py-1"}
            />
          </div>
        </div>
      </div>

      <div
        style={{ width: "275px" }}
        className="d-flex flex-column justify-content-between h-100 pt-1"
      >
        <div className="px-4 py-4 card-bcm-breakdown">
          <div className="pb-3 mb-1 performance-card-header">
            <div className="bcm-breakdown-header ">BCM Breakdown</div>
          </div>

          <div className="performance-section py-4">
            <div className="d-flex justify-content-between">
              <div className="bcm-breakdown-label">BCM Within Geofences</div>
              <span className="bcm-breakdown-value">{data?.BCMGeofence?.inside ?? "-"}</span>
            </div>

            {/* Target Section */}
            <div className="d-flex justify-content-between">
              <div className=" bcm-breakdown-label">BCM Outside Geofences</div>
              <span className="bcm-breakdown-value">{data?.BCMGeofence?.outside ?? "-"}</span>
            </div>
          </div>
        </div>
        <div style={{ padding: "8px 20px" }} className="card-bcm-breakdown">
          <div className="d-flex justify-content-between d-flex align-items-center">

            <div className=" bcm-breakdown-label d-flex align-items-center"> <MdOutlineSignalCellularAlt className="mr-2" style={{ marginBottom: 1.75 }} color="#4F74D9" size={15} />
              <div>Previous Shift Total</div>
            </div>
            <span className="bcm-breakdown-value">
              <span
                style={{
                  color:
                    data?.previousShiftActualBCM > data?.previousShiftTotalBCM
                      ? "#419800"
                      : "#000000",
                }}
              >
                {data?.previousShiftActualBCM ?? "-"}
              </span>{" "}
              / {data?.previousShiftTotalBCM ?? "-"} BCM
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Performance;
