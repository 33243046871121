import AssetProgressBar from "pages/Dashboard/components/AssetProgressBar/AssetProgressBar";
import ExcavatorsTruckDetail from "../ExcavatorsTruckDetails/ExcavatorsTruckDetails";
import "../ExcavatorCard/ExcavatorCard.css";
import { Status } from "pages/Dashboard/constant";
import AssetBadge from "pages/Dashboard/components/AssetBadge";
import { FaCircleXmark } from "react-icons/fa6";
import { IoBanSharp } from "react-icons/io5";
import moment from "moment";

const statusPriority = {
  [Status.ASSIGNED]: 1,
  [Status.REASSIGNED]: 1,
  [Status.UNASSIGNED]: 2,
  [Status.UNSCHEDULED]: 3,
  [Status.REMOVED]: 4,
};


// ExcavatorCard Component
const ExcavatorCard = ({
  excavatorName,
  operatorName,
  actualBCM,
  targetBCM,
  average,
  status,
  truckAssignments,
  index,
  productiveHours,
  shiftStartTime,
  shiftEndTime,
  hourlyData
}) => (
  <div
    className="ex-card"
    style={{
      backgroundColor:
        status === Status.REMOVED || status === Status.SHIFTENDED
          ? "#F0F0F0"
          : status === Status.UNSCHEDULED ? "#F4F6FD" : "none",
    }}
  >
    <div className="pb-4 border-bottom-1 excavator-card-header">
      <div className="d-flex justify-content-between align-item-center">
        <div className="d-flex align-items-center" style={{ font: "normal normal 600 14px/19px Open Sans" }}>
          <div>{excavatorName}</div>
          {status === Status.SHIFTENDED && <FaCircleXmark className="ml-1" size={12} />}
          {status === Status.UNSCHEDULED && <IoBanSharp className="ml-1" size={12} />}
        </div>
        <div
          style={{
            font: "normal normal normal 14px / 19px Open Sans",
          }}
        >
          <span style={{ font: "normal normal 600 14px/19px Open Sans" }}>
            {actualBCM}
          </span>
          /{targetBCM ?? '-'} BCM
        </div>
      </div>
      <div className="d-flex justify-content-between align-item-center my-1">
        <div className="operatorName">O: {operatorName ? operatorName : "Unknown Operator"}</div>

        <AssetProgressBar
          width={175}
          value={actualBCM}
          max={targetBCM}
          thresholdNeeded={status !== Status.UNSCHEDULED}
          arrowDirection={(
            (actualBCM < targetBCM && (hourlyData[
              moment().isBefore(moment(shiftEndTime))
                ? moment().tz("Pacific/Auckland").subtract(1, 'hour').format("HH:00")
                : moment(shiftEndTime).tz("Pacific/Auckland").subtract(1, 'hour').format("HH:00")
            ] ?? 0) < Math.round(targetBCM / productiveHours))
              ? "left"
              : "right"
          )}
          shadowColor={status !== Status.UNSCHEDULED ? false : true}
          progressContainerStyle={
            status === Status.UNSCHEDULED
              ? { background: 'transparent linear-gradient(270deg, #AAB7DB 0%, #7A88C6 100%)' }
              : {}
          }
          arrowNeeded={status !== Status.UNSCHEDULED}
        />

      </div>
      <div className="d-flex justify-content-between align-items-center average mt-4 mb-2">
        {status === Status.REMOVED || status === Status.REASSIGNED ? (
          <AssetBadge
            className="px-3"
            containerStyle={{
              border: "none",
              padding: "3.5px 0px",
              marginTop: "0px !important",
            }}
            value={
              status === Status.REMOVED
                ? "Removed"
                : status === Status.REASSIGNED
                  ? "Reassigned"
                  : ""
            }
            color={
              status === Status.REMOVED
                ? "#2B2B2B"
                : status === Status.REASSIGNED
                  ? "#000000"
                  : ""
            }
            bgColor={
              status === Status.REMOVED
                ? "#D8D8D8"
                : status === Status.REASSIGNED
                  ? "#D8E5FF"
                  : ""
            }
            font={"normal normal normal 9px/12px Open Sans"}
          />
        ) : (
          <div></div>
        )}

        <div className="align-self-end">
          Average/hr:{" "}
          <span style={{ color: "black" }} className="averageData">
            {average} BCM
          </span>
        </div>
      </div>
    </div>
    <div className="trucksSection">
      <span className="truckCount pt-3 pb-3 ">
        Trucks ({truckAssignments?.filter(x => x.status != Status.REMOVED).length})
      </span>
      <ExcavatorsTruckDetail
        productiveHours={productiveHours}
        truckAssignments={truckAssignments.sort((a, b) => {
          // Sort by status priority
          const statusDiff = statusPriority[a.status] - statusPriority[b.status];
          if (statusDiff !== 0) return statusDiff;

          // Secondary sorting within ASSIGNED status: actualLoads vs. targetLoads
          if (
            statusPriority[a.status] == 1 &&
            statusPriority[a.status] == 1
          ) {
            return a.actualLoads < a.targetLoads ? ((b.actualLoads < b.targetLoads) ? 0 : -1) : (b.actualLoads < b.targetLoads ? 1 : 0);
          }

          return 0;
        })}
      />
    </div>
  </div>
);

export default ExcavatorCard;
