import React, { useEffect, useRef, useState } from "react";
import styles from "./EquimentPlan.module.css";
import EquipmentPlanHeaders from "./EquipmentPlanHeaders";
import EquipmentPlanTableRow from "./SubComponents/EquipmentPlanTableRow/EquipmentPlanTableRow";
import SelectAsset from "../../SelectAsset/SelectAsset";
import { formateDataForView, getTargetLoads } from "../../../utils/helper";
import { getExShiftPlanUpdates, getTruckUpdates } from "../../../utils/helpers/FormUpdates";
import { AssetCategories, DateTimeFormats, JumpToSection, JumpToSectionOptions, SelectAssetTypes, ShiftStatus } from "../../../utils/contants";
import ReassignTrucks from "../../ReassignTrucks/ReassignTrucks";
import RemovedEquipments from "./SubComponents/RemovedEquipments";
import AddEquipmentButton from "./components/AddEquipmentButton/AddEquipmentButton";
import { createExcavatorDetailFromAssignment, createExcavatorDetailFromEquipmentData, createHaulTruckDetailFromAssignment, updateHaulTrucksForSelected } from "./utils/helper";
import ExcavatorShiftDetails from "../../ExcavatorShiftDetails/ExcavatorShiftDetails";
import TruckShiftDetails from "../../TruckShiftDetails/TruckShiftDetails";
import { updateShiftPlans, updateHaulTrucks, clearPreviousOperator } from "./SubComponents/EquipmentPlanTableRow/utils/helper";
import moment from "moment";
import SelectField from "../../../components/GeneralFields/SelectField";
import { useAddDailyPlan } from "../../../Context/context";

const EquipmentPlan = ({
  handleSearchCount,
}) => {
  const {
    removedEquipments,
    setRemovedEquipments,
    originalData,
    search,
    shiftStatus,
    loading,
    assignedExcavatorDetailsFormData,
    setAssignedExcavatorDetailsFormData,
    isTruckShiftDetailsFlyerOpen,
    generalShiftPlanDetailsFormData,
    isExcavatorShiftDetailsFlyerOpen,
    canEditShift,
    startSession,
    stopFetching,
    closeFlyer
  } = useAddDailyPlan();

  const [showAddExcavatorPopup, setShowAddExcavatorPopup] = useState(false);
  const [showAddTruckPopup, setShowAddTruckPopup] = useState(false)
  const [selectedExcavatorIdx, setSelectedExcavatorIdx] = useState(0);
  const [showReassignRemovedTruck, setShowReassignRemovedTruck] = useState({
    show: false,
    truck: null,
  });

  // Jump To Functionality
  const [jumpTo, setJumpTo] = useState('');
  const excavatorAndTrucksRef = useRef(null);
  const removedEquipmentsRef = useRef(null);
  const handleJumpTo = (section) => {
    setJumpTo(section);
    if (section !== '') {
      if (section === JumpToSection.ExcavatorsAndTrucks && excavatorAndTrucksRef.current) {
        excavatorAndTrucksRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      } else if (section === JumpToSection.RemovedEquipments && removedEquipmentsRef.current) {
        removedEquipmentsRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  }
  //Add Assets
  const handleAddExcavator = async (selected) => {
    if (shiftStatus != ShiftStatus.NEW && selected.length > 0) {
      try {
        await startSession(generalShiftPlanDetailsFormData.newShiftId, shiftStatus)
      } catch (error) {
        return
      }
    }

    setAssignedExcavatorDetailsFormData((prev) => [
      ...prev,
      ...selected.map((selectedExcavator) => createExcavatorDetailFromEquipmentData(selectedExcavator, generalShiftPlanDetailsFormData))
    ]);
  };

  const handleAddTruck = async (selected) => {
    if (shiftStatus != ShiftStatus.NEW && selected.length > 0) {
      try {
        await startSession(generalShiftPlanDetailsFormData.newShiftId, shiftStatus)
      } catch (error) {
        return
      }
    }

    setAssignedExcavatorDetailsFormData(prev =>
      prev.map((excavator, idx) => {
        if (idx === selectedExcavatorIdx) {
          const updatedHaulTrucks = !((shiftStatus == ShiftStatus.ONGOING || shiftStatus == ShiftStatus.PUSBLISHED) && (excavator?.status == ShiftStatus.ENDED || moment(excavator.endTime, DateTimeFormats.DATE_TIME_U).diff(moment(), 'minutes') <= 10)) ? updateHaulTrucksForSelected(excavator.haulTrucks, selected, excavator)?.map((htPlan) => ({ ...htPlan, plan: excavator?.plan })) : excavator.haulTrucks;

          const updatedExcvatorHaulTrucks = [
            ...excavator.excavatorHaulTrucks,
            ...selected.map(haulTruck => ({
              value: haulTruck._id,
              label: haulTruck.Unit,
              bcmPerLoad: excavator?.material?.label == "Coal" ? haulTruck.ob_coal : haulTruck.ob_bcm
            }))
          ]

          const updatedShiftPlans = excavator.shiftPlans.map(shiftPlan => {
            const updatedPlanHaulTrucks = ((shiftStatus == ShiftStatus.ONGOING || shiftStatus == ShiftStatus.PUSBLISHED) && (shiftPlan?.status == ShiftStatus.ENDED || moment(shiftPlan.endTime, DateTimeFormats.DATE_TIME_U).diff(moment(), 'minutes') <= 10)) ? shiftPlan.haulTrucks : updateHaulTrucksForSelected(shiftPlan.haulTrucks, selected, shiftPlan)?.map((htPlan) => ({ ...htPlan, plan: shiftPlan?.plan }));

            return {
              ...shiftPlan,
              haulTrucks: updatedPlanHaulTrucks
            };
          });

          return {
            ...excavator,
            haulTrucks: updatedHaulTrucks,
            shiftPlans: updatedShiftPlans,
            excavatorHaulTrucks: updatedExcvatorHaulTrucks
          };
        }
        return excavator;
      })
    );
  };
  //Reassign Removed Assets
  const handleReassignRemovedTruck = async (excavatorPlantId, haulTruck) => {
    if (shiftStatus != ShiftStatus.NEW)
      try {
        await startSession(generalShiftPlanDetailsFormData.newShiftId, shiftStatus)
      } catch (error) {
        return
      }

    setShowReassignRemovedTruck({ show: false, truck: null });

    setAssignedExcavatorDetailsFormData((prev) =>
      prev.map((ex) => {
        if (ex.plantId !== excavatorPlantId) return ex;

        const updatedHaulTrucks = ((shiftStatus == ShiftStatus.ONGOING || shiftStatus == ShiftStatus.PUSBLISHED) && (ex?.status == ShiftStatus.ENDED || moment(ex.endTime, DateTimeFormats.DATE_TIME_U).diff(moment(), 'minutes') <= 10)) ? ex.haulTrucks : [...ex.haulTrucks, { ...createHaulTruckDetailFromAssignment(haulTruck, ex), actualLoads: excavatorPlantId == haulTruck?.loadingMachine?.label ? (haulTruck?.shiftPlans[ex.plan?.charCodeAt(0) - 65]?.actualLoads ?? 0) : 0 }];
        const excavatorHaulTrucks = [...ex.excavatorHaulTrucks, { label: haulTruck.plantId, value: haulTruck?.equipment, bcmPerLoad: haulTruck?.bcmPerLoad }]
        const targetLoads = getTargetLoads(ex, updatedHaulTrucks);

        const updateHaulTrucksWithLoads = (haulTrucks) =>
          haulTrucks.map((ht) => ({
            ...ht,
            targetLoads: (ht?.actualLoads || 0) + targetLoads,
          }));

        return {
          ...ex,
          excavatorHaulTrucks: excavatorHaulTrucks,
          haulTrucks: updateHaulTrucksWithLoads(updatedHaulTrucks),
          shiftPlans: ex.shiftPlans.map((shiftPlan, idxShiftPlan) => {
            if (((shiftStatus == ShiftStatus.ONGOING || shiftStatus == ShiftStatus.PUSBLISHED) && (shiftPlan?.status == ShiftStatus.ENDED || moment(shiftPlan.endTime, DateTimeFormats.DATE_TIME_U).diff(moment(), 'minutes') <= 10)))
              return shiftPlan
            const updatedPlanHaulTrucks = [
              ...shiftPlan.haulTrucks,
              { ...createHaulTruckDetailFromAssignment(haulTruck, shiftPlan), actualLoads: excavatorPlantId == haulTruck?.loadingMachine?.label ? (haulTruck?.shiftPlans?.[idxShiftPlan]?.actualLoads ?? 0) : 0 },
            ];
            const planTargetLoads = getTargetLoads(shiftPlan, updatedPlanHaulTrucks);

            return {
              ...shiftPlan,
              haulTrucks: updateHaulTrucksWithLoads(updatedPlanHaulTrucks, planTargetLoads),
            };
          }),
        };
      })
    );

    setRemovedEquipments((prev) => prev.filter((as) => as.plantId !== haulTruck.plantId));
  };

  const handleReassignRemovedExcavators = async (data) => {
    if (shiftStatus != ShiftStatus.NEW) {
      if (!stopFetching) {
        try {
          await startSession(generalShiftPlanDetailsFormData.newShiftId, shiftStatus)
        } catch (error) {
          return
        }
      }
    }

    const calculateProductiveHours = (startTime, endTime, removalTime, assignmentTime, productiveHours = 0) => {
      const startMoment = moment(startTime, DateTimeFormats.DATE_TIME_U);
      const endMoment = moment(endTime, DateTimeFormats.DATE_TIME_U);
      const assignmentMoment = moment(assignmentTime);
      const removedMoment = moment(removalTime);

      const totalHours = endMoment.diff(startMoment, 'hours', true);
      const pastHours = removedMoment.diff(startMoment, 'hours', true);
      const remainingHours = endMoment.diff(assignmentMoment, 'hours', true);

      if (totalHours <= 0 || pastHours < 0 || remainingHours < 0) {
        return 0;
      }

      const totalWorkedHours = pastHours + remainingHours;
      return Math.floor(productiveHours * (totalWorkedHours / totalHours));
    };


    const productiveHours = calculateProductiveHours(data.startTime, data.endTime, data?.removalTime ?? moment().subtract(2, 'minutes'), moment(), data?.productiveHours?.value);
    const targetBCMs = (data.bcmPerHour ?? 0) * productiveHours;

    setAssignedExcavatorDetailsFormData((prev) => [...prev,
    createExcavatorDetailFromAssignment({ ...data, productiveHours: { value: productiveHours, label: productiveHours?.toString() }, targetBCMs: targetBCMs }, generalShiftPlanDetailsFormData)
    ])
    setRemovedEquipments((prev) => {
      return prev.filter((as) => as.plantId !== data.plantId);
    })
  }

  const handleReassignRemovedAsset = (data, direct = false) => {
    if (data.category === AssetCategories.EXCAVATORS) {
      handleReassignRemovedExcavators(data, generalShiftPlanDetailsFormData)
    } else if (data.category === AssetCategories.TRUCKS) {
      if (direct)
        handleReassignRemovedTruck(assignedExcavatorDetailsFormData?.[0]?.plantId, data)
      else
        setShowReassignRemovedTruck({ show: true, truck: data });

    }
  };

  //Handle Save Details for Ex and Trucks
  //?this can be improved
  const handleSaveDetails = async (
    data,
    currPlanId,
    dontWantToClose,
    overlappingDetails,
    type,
    exIndex,
    truckId
  ) => {

    if (shiftStatus != ShiftStatus.NEW) {
      const orEx = originalData?.find(e => e.plantId == assignedExcavatorDetailsFormData[exIndex]?.plantId)
      const updates = getExShiftPlanUpdates(orEx?.shiftPlans?.[currPlanId], data)
      if (updates.length > 0) {
        try {
          await startSession(generalShiftPlanDetailsFormData.newShiftId, shiftStatus)
        } catch (error) {
          return
        }
      }
      else {
        const updates = getTruckUpdates(orEx?.shiftPlans?.[currPlanId]?.haulTrucks, data)
        if (updates.length > 0) {
          try {
            await startSession(generalShiftPlanDetailsFormData.newShiftId, shiftStatus)
          } catch (error) {
            return
          }
        }
      }
    }



    setAssignedExcavatorDetailsFormData(prev => {

      return prev.map((exv, i) => {
        let ex = { ...exv };

        ex = clearPreviousOperator(ex, i, overlappingDetails);

        if (i !== exIndex) return ex;

        const currentTime = moment();

        const updatedShiftPlans = updateShiftPlans(ex?.shiftPlans, data, currPlanId, type, ex, truckId);

        const latestPlan = updatedShiftPlans.find(shiftPlan =>
          moment(shiftPlan.startTime, DateTimeFormats.DATE_TIME_U).isSameOrBefore(currentTime) &&
          moment(shiftPlan.endTime, DateTimeFormats.DATE_TIME_U).isAfter(currentTime)
        ) || updatedShiftPlans.find(shiftPlan =>
          moment(shiftPlan.endTime, DateTimeFormats.DATE_TIME_U).isBefore(currentTime)
        ) || updatedShiftPlans.find(shiftPlan =>
          moment(shiftPlan.startTime, DateTimeFormats.DATE_TIME_U).isSameOrAfter(currentTime)
        ) || ex;

        const isMatchingPlan = data.plan === latestPlan.plan;
        const updatedExcavatorCurrPlan = type === "excavator" ? latestPlan : {};

        const updatedTrucks = isMatchingPlan
          ? updateHaulTrucks(latestPlan?.haulTrucks, data, ex?.excavatorHaulTrucks, latestPlan?.removedHaulTrucks, truckId)
          : { haulTrucks: ex.haulTrucks, removedHaulTrucks: ex?.removedHaulTrucks };

        return {
          ...ex,
          ...updatedExcavatorCurrPlan,
          haulTrucks: updatedTrucks?.haulTrucks || updatedTrucks,
          removedHaulTrucks: updatedTrucks?.removedHaulTrucks || ex.removedHaulTrucks,
          shiftPlans: updatedShiftPlans,
        };
      })
    }

    );

    if (!dontWantToClose)
      closeFlyer(type)
  };

  useEffect(() => {
    const countMatches = (data, query) => {
      let count = 0;
      query = query.toLowerCase();

      const checkMatch = (value) => {
        if (value && query) {
          value = value.toLowerCase();
          const regex = new RegExp(query, 'g');
          const matches = value.match(regex);
          if (matches) {
            count = count + matches.length;
          }
        }
      };

      data.forEach((ex) => {
        const formattedExData = formateDataForView(ex);
        checkMatch(formattedExData.plantId);
        checkMatch(formattedExData.category);
        checkMatch(formattedExData.material);
        checkMatch(formattedExData.operator);
        checkMatch(formattedExData.sourceArea);
        checkMatch(formattedExData.dumpArea);

        ex.haulTrucks.forEach((truck) => {
          const formatedTruckData = formateDataForView(truck);
          checkMatch(formatedTruckData.plantId);
          checkMatch(formatedTruckData.category);
          checkMatch(formatedTruckData.material);
          checkMatch(formatedTruckData.operator);
          checkMatch(formatedTruckData.sourceArea);
          checkMatch(formatedTruckData.dumpArea);
        });
      });

      return count;
    };
    handleSearchCount(countMatches(assignedExcavatorDetailsFormData, search.query))
  }, [search.query]);

  return (
    <>
      {showAddExcavatorPopup && canEditShift && (
        <SelectAsset
          timeRange={{ startTime: generalShiftPlanDetailsFormData.startTime, endTime: generalShiftPlanDetailsFormData.endTime }}
          title="Add Excavators"
          onClose={() => setShowAddExcavatorPopup(false)}
          type={SelectAssetTypes.EXCAVATORS}
          alreadySelectedAssets={[...assignedExcavatorDetailsFormData, ...removedEquipments]}
          handleAddAsset={handleAddExcavator}
        />
      )}

      {showAddTruckPopup && canEditShift && (
        <SelectAsset
          timeRange={{ startTime: generalShiftPlanDetailsFormData.startTime, endTime: generalShiftPlanDetailsFormData.endTime }}
          title={`${assignedExcavatorDetailsFormData[selectedExcavatorIdx]?.plantId} - Add Haul Trucks`}
          onClose={() => setShowAddTruckPopup(false)}
          type={SelectAssetTypes.TRUCKS}
          alreadySelectedAssets={[...assignedExcavatorDetailsFormData.reduce((prev, curr) => [...prev, ...curr.excavatorHaulTrucks], []), ...removedEquipments]}
          handleAddAsset={handleAddTruck}
        />
      )}

      {showReassignRemovedTruck.show && canEditShift && (
        <ReassignTrucks
          removed
          onClose={() => setShowReassignRemovedTruck({ show: false, truck: null })}
          truck={showReassignRemovedTruck.truck}
          excavators={assignedExcavatorDetailsFormData}
          handleReassignTruck={handleReassignRemovedTruck}
        />
      )}

      {(isExcavatorShiftDetailsFlyerOpen >= 0) && (
        <ExcavatorShiftDetails
          assignedExcavatorDetailsFormData={assignedExcavatorDetailsFormData}
          singleAssignedExcavatorDetailsFormData={assignedExcavatorDetailsFormData[isExcavatorShiftDetailsFlyerOpen]}
          onSaveExcavatorDetails={(data, currPlanId, dontWantToClose, overlappingDetails) =>
            handleSaveDetails(data, currPlanId, dontWantToClose, overlappingDetails, "excavator", isExcavatorShiftDetailsFlyerOpen)
          }
          isOpen={(isExcavatorShiftDetailsFlyerOpen >= 0)}
          onClose={() => closeFlyer("excavator")}
        />
      )}

      {isTruckShiftDetailsFlyerOpen && (
        <TruckShiftDetails
          assignedTruckDetailsId={isTruckShiftDetailsFlyerOpen.id}
          assignedTruckDetailsIdx={isTruckShiftDetailsFlyerOpen.idx}
          isOpen={isTruckShiftDetailsFlyerOpen}
          singleAssignedExcavatorDetailsFormData={assignedExcavatorDetailsFormData[isTruckShiftDetailsFlyerOpen.exIdx]}
          onSaveTruckDetails={(data, currPlanId, dontClose, operatorLastLocation) =>
            handleSaveDetails(data, currPlanId, dontClose, operatorLastLocation, "truck", isTruckShiftDetailsFlyerOpen.exIdx, isTruckShiftDetailsFlyerOpen.id)
          }
          onClose={() => closeFlyer("truck")}
        />
      )}

      {/* Main Component */}
      <div className={styles.container}>
        <div className={`${styles.heading} ${styles.roundedHeading}`} style={{ position: 'sticky', top: '0px', zIndex: '1' }}>
          <span>Equipment Plan</span>
          <SelectField
            placeholder={"Jump To"}
            options={removedEquipments.length > 0 ? JumpToSectionOptions : JumpToSectionOptions.filter((option) => option.label !== JumpToSection.RemovedEquipments)}
            value={jumpTo}
            onChange={handleJumpTo}
            style={{
              height: "30px",
              width: "160px",
              marginTop: 2.5,
              borderRadius: "15px",
              backgroundColor: "white",
              paddingLeft: "10px",
              border: "1px solid #E1E8F4",
              color: "#1A1A1A",
              fontSize: "12px",
              fontWeight: "600",
            }}
            mainContainerStyle={{ marginBottom: "0px" }}
          />
        </div>

        <EquipmentPlanHeaders />

        <div ref={excavatorAndTrucksRef} className={`${styles.heading} ${!assignedExcavatorDetailsFormData?.length && styles.marginBottom}`}>
          <span>Excavators & Trucks</span>
          {canEditShift && generalShiftPlanDetailsFormData?.crew && <AddEquipmentButton isDisabled={loading} label={"Excavators"} onClick={() => setShowAddExcavatorPopup(true)} />}
        </div>

        {assignedExcavatorDetailsFormData.map((excavator, index) => (
          <EquipmentPlanTableRow
            key={index}
            onTruckAddClick={(excavatorIdx) => {
              setShowAddTruckPopup(true);
              setSelectedExcavatorIdx(excavatorIdx)
            }}
            searchQuery={search.query}
            index={index}
            excavatorViewOnly={formateDataForView(excavator)}
          />
        ))}

        {removedEquipments.length > 0 && (
          <RemovedEquipments
            searchQuery={search.query}
            handleReassignRemovedAsset={handleReassignRemovedAsset}
            removedSectionRef={removedEquipmentsRef}
          />
        )}
      </div>
    </>
  );
};

export default EquipmentPlan;