import React, { useEffect, useState } from 'react';
import styles from "./History.module.css";
import WaterfallModal from "../../../components/WaterfallModal/WaterfallModal";
import ShiftPlanFields from './ShiftPlanFields';
import { GoKebabHorizontal } from "react-icons/go";
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import moment from 'moment';
import { DateTimeFormats } from '../../../utils/contants';

export default function ShiftPlan({
    multi,
    showError,
    viewOnly,
    setFormData,
    expandAll,
    handleEditMultiPlan,
    timingsOverlapping,
    formData,
    canEditShift,
    isHaulTrucksSelectionDisabled,
    materialSelected
}) {

    const [isMandateErrorOpen, setIsMandateErrorOpen] = useState(showError);
    const handleFieldChange = (field, value) => {
        setFormData(prevState => ({
            ...prevState,
            [field]: value,
        }));
    };

    useEffect(() => {
        setIsMandateErrorOpen(showError)
    }, [showError]);

    if (multi) {
        return <div className={styles.container}>
            {timingsOverlapping && <ErrorMessage message={"Shift plans timings are overlaping : Please adjust timings"} hideClose={true} />}
            {formData?.shiftPlans?.map((shiftPlan, index) => {
                return (
                    <WaterfallModal
                        key={index}
                        isOpen={expandAll === index || expandAll}
                        triggerContent={`Plan ${shiftPlan.plan}_${shiftPlan.startTime.slice(12)} - ${shiftPlan.endTime.slice(12)}`}
                        actionMenuOptions={[(shiftPlan?.status != "E" && canEditShift && (!shiftPlan?.status || moment(shiftPlan.endTime, DateTimeFormats.DATE_TIME_U).diff(moment(), 'minutes') >= 10)) && { name: 'Edit', onClick: () => { handleEditMultiPlan(index) } },
                        { name: 'Download PDF', onClick: () => { } },
                        { name: 'Download CSV', onClick: () => { } },
                        ].filter(Boolean)}
                        actionIcon={<GoKebabHorizontal color='black' size={20} />}
                        content={<ShiftPlanFields
                            formData={{
                                ...shiftPlan,
                                plantId: formData.plantId,
                                category: formData.category,
                                haulTrucks: shiftPlan?.haulTrucks?.map((ht) => ({ value: ht?.equipment, label: ht?.plantId }))
                            }}
                            isMandateErrorOpen={isMandateErrorOpen}
                            setIsMandateErrorOpen={setIsMandateErrorOpen}
                            viewOnly={true}
                            handleFieldChange={handleFieldChange}
                            showError={showError}
                        />}
                    />
                );
            })}
        </div>
    }

    return (
        <ShiftPlanFields
            materialSelected={materialSelected}
            isHaulTrucksSelectionDisabled={isHaulTrucksSelectionDisabled}
            formData={formData}
            isMandateErrorOpen={isMandateErrorOpen}
            setIsMandateErrorOpen={setIsMandateErrorOpen}
            viewOnly={viewOnly}
            handleFieldChange={handleFieldChange}
            showError={showError}
        />
    );
}