import { getTargetLoads } from "../../../../utils/helper";

export const createExcavatorDetailFromEquipmentData = (selectedExcavator, shiftDetails) => {
    const targetBCMs = parseInt(selectedExcavator?.bcm_hr) * parseInt(shiftDetails?.productiveHours?.value);

    return {
        equipment: selectedExcavator._id,
        plantId: selectedExcavator.Unit,
        category: "Excavator",
        bcmPerHour: selectedExcavator?.bcm_hr,
        excavatorHaulTrucks: [],
        //!Plan A to Show
        plan: "A",
        startTime: shiftDetails.startTime,
        endTime: shiftDetails.endTime,
        operator: null,
        assistantOperator: null,
        trainee: null,
        material: null,
        sourceArea: null,
        dumpArea: [],
        haulTrucks: [],
        removedHaulTrucks: [],
        productiveHours: shiftDetails.productiveHours,
        activityDescription: null,
        knownHazards: "",
        startOfShiftNoteToOperator: "",
        targetBCMs: targetBCMs,
        shiftPlans: [
            {
                plan: "A",
                startTime: shiftDetails.startTime,
                endTime: shiftDetails.endTime,
                operator: null,
                assistantOperator: null,
                trainee: null,
                material: null,
                sourceArea: null,
                dumpArea: [],
                haulTrucks: [],
                removedHaulTrucks: [],
                productiveHours: shiftDetails.productiveHours,
                activityDescription: null,
                knownHazards: "",
                startOfShiftNoteToOperator: "",
                targetBCMs: targetBCMs,
            }
        ]
    };
};

export const createExcavatorDetailFromAssignment = (data, shiftDetails) => {

    return {
        equipment: data.equipment,
        plantId: data.plantId,
        category: "Excavator",
        bcmPerHour: data?.bcmPerHour,
        excavatorHaulTrucks: [],
        //!Plan A to Show
        plan: "A",
        startTime: shiftDetails.startTime,
        endTime: shiftDetails.endTime,
        operator: null,
        assistantOperator: null,
        trainee: null,
        material: null,
        sourceArea: null,
        dumpArea: [],
        haulTrucks: [],
        removedHaulTrucks: [],
        productiveHours: data?.productiveHours ?? shiftDetails?.productiveHours,
        activityDescription: null,
        knownHazards: "",
        startOfShiftNoteToOperator: "",
        actualBCMs: data?.actualBCMs ?? 0,
        targetBCMs: data?.targetBCMs ?? (parseInt(data?.bcmPerHour) * parseInt(data?.productiveHours?.value)),
        shiftPlans: [
            {
                plan: "A",
                startTime: shiftDetails.startTime,
                endTime: shiftDetails.endTime,
                operator: null,
                assistantOperator: null,
                trainee: null,
                material: null,
                sourceArea: null,
                dumpArea: [],
                haulTrucks: [],
                removedHaulTrucks: [],
                productiveHours: data?.productiveHours ?? shiftDetails?.productiveHours,
                activityDescription: null,
                knownHazards: "",
                startOfShiftNoteToOperator: "",
                actualBCMs: data?.actualBCMs ?? 0,
                targetBCMs: data?.targetBCMs ?? (parseInt(data?.bcmPerHour) * parseInt(data?.productiveHours?.value)),
            }
        ]
    }
};

export const createHaulTruckDetailFromEquipmentData = (haulTruck, excavator, materialType = "Overburden") => ({
    equipment: haulTruck._id,
    plantId: haulTruck.Unit,
    category: 'Truck',
    operator: null,
    assistantOperator: null,
    trainee: null,
    material: excavator.material,
    sourceArea: excavator.sourceArea,
    dumpArea: excavator.dumpArea,
    targetLoads: 0,
    bcmPerLoad: (excavator?.material?.label == "Coal" ? haulTruck.ob_coal : haulTruck.ob_bcm) || 1,
    loadingMachine: { value: excavator.equipment, label: excavator.plantId },
    startTime: excavator.startTime,
    endTime: excavator.endTime,
    productiveHours: excavator.productiveHours,
    activityDescription: null,
    knownHazards: '',
    startOfShiftNoteToOperator: ""
});

export const createHaulTruckDetailFromAssignment = (haulTruck, excavator) => ({
    equipment: haulTruck.equipment,
    plantId: haulTruck.plantId,
    category: 'Truck',
    operator: null,
    assistantOperator: null,
    trainee: null,
    shiftPlan: excavator?.plan,
    material: excavator?.material,
    sourceArea: excavator?.sourceArea,
    dumpArea: excavator?.dumpArea,
    targetLoads: 0,
    bcmPerLoad: haulTruck?.bcmPerLoad,
    loadingMachine: excavator.equipment ? { value: excavator.equipment, label: excavator.plantId } : { value: haulTruck.equipment, label: haulTruck.plantId },
    startTime: excavator.startTime,
    endTime: excavator.endTime,
    productiveHours: excavator?.productiveHours,
    activityDescription: null,
    knownHazards: '',
    startOfShiftNoteToOperator: ""
});

export const updateHaulTrucksForSelected = (existingHaulTrucks, selectedHaulTrucks, excavator) => {
    const newHaulTrucks = selectedHaulTrucks.map(haulTruck => createHaulTruckDetailFromEquipmentData(haulTruck, excavator));
    const combinedHaulTrucks = [...existingHaulTrucks, ...newHaulTrucks];
    const targetLoads = getTargetLoads(excavator, combinedHaulTrucks);

    return combinedHaulTrucks.map(ht => ({
        ...ht,
        targetLoads: (ht?.actualLoads || 0) + targetLoads
    }));
};