import React, { useState, useRef, useEffect, useContext, createContext } from 'react';
import useAuth from '../../../hooks/useAuth';
import { CallWithAuth, CallWithAuthFormData } from '../../../action/apiActions';
import {
    // GET_PRESENT_TOMMOROW_DATA,
    // GET_PRESENT_FORECAST_DATA,
    // GET_WEBHOOK_DATA,
    // GET_ASSET_PERFORMANCE,
    GET_DUMP_CYCLE_DATA
} from '../../../action/apiPath';
// import { getAlert, timeDiff } from '../../../utils/helper';
// import { weatherDataInitialState } from './initialState';
import { formatDate } from '../DumpCycleTracker/helper';
import axios from 'axios';


const DashboardContext = createContext()

export function DashboardContextProvider({ children }) {

    let shiftTiming = {
        date: '',
        end: '',
        start: ''
    }
    // const [breakdownEquipments, setBreakdownEquipments] = useState({
    //     excavators: [],
    //     trucks: []
    // });
    const [fallingBehind, setFallingBehind] = useState({
        excavators: [],
        trucks: []
    }); //
    // const [equipments, setEquipments] = useState({
    //     excavators: [],
    //     trucks: []
    // })
    // const [shiftPerformance, setShiftPerformance] = useState(undefined)
    // const [shiftDetail, setShiftDetail] = useState(undefined);
    // const [shiftInfo, setShiftInfo] = useState(undefined);
    // const [completeShiftData, setCompleteShiftData] = useState(undefined);
    // const [weatherData, setWeatherData] = useState(weatherDataInitialState);
    const [loading, setLoading] = useState(true);
    const { auth } = useAuth();
    const [shiftChoiceForDumpTracker, setShiftChoiceForDumpTracker] = useState(0)//
    const [dumpCycleTrackerData, setDumpCycleTrackerData] = useState([]); //
    const [dateForDumpTracker, setDateForDumpTracker] = useState(''); //
    const dateForDumpTrackerRef = useRef(''); //
    const shiftChoiceForDumpTrackerRef = useRef(0); //
    const dumpCycleSourceRef = useRef(null); //
    const [dumpTrackerLoading, setDumpTrackerLoading] = useState(true) //
    useEffect(() => {
        setDumpTrackerLoading(true);
        fetchDumpCycleTrackerData('one')


    }, [dateForDumpTracker])

    // const getTodayAndTomorrowData = async () => {

    //     try {
    //         const response = await CallWithAuth('GET', GET_PRESENT_TOMMOROW_DATA);

    //         const { tomorrowData, data: presentDayData } = response?.res?.data?.data;
    //         setWeatherData((prevState) => ({
    //             ...prevState,
    //             todayData: {
    //                 temperature: presentDayData?.temperature,
    //                 max_temp: presentDayData?.max_temp,
    //                 min_temp: presentDayData?.min_temp,
    //                 rain_so_far: presentDayData?.rain_so_far,
    //                 feels_like: response?.res?.data?.data?.feel_data,
    //             },
    //             tomorrowData: {
    //                 cloud_cover: tomorrowData?.cloud_cover,
    //                 humidity: tomorrowData?.humidity,
    //                 max_temp: tomorrowData?.max_temp,
    //                 min_temp: tomorrowData?.min_temp,
    //                 rain_so_far: tomorrowData?.rain_so_far,
    //                 uv: tomorrowData?.uv,
    //                 wind_direction: tomorrowData?.wind_direction,
    //                 wind_gusts: tomorrowData?.wind_gusts,
    //                 wind_speed: tomorrowData?.wind_speed,
    //                 pressure: tomorrowData?.pressure,
    //             },
    //         }));
    //     } catch (error) {

    //     }
    //     return setTimeout(getTodayAndTomorrowData, 180000)
    // }

    // const getForecastData = async () => {

    //     try {
    //         const payload = {
    //             timestamp: Math.floor(new Date().getTime() / 1000),
    //         };
    //         const response = await CallWithAuth('POST', GET_PRESENT_FORECAST_DATA, payload);
    //         const { message } = response?.res?.data;
    //         setWeatherData((prevState) => ({
    //             ...prevState,
    //             forecast: {
    //                 temp: message?.temperature,
    //                 cloudCover: message?.cloudCover,
    //             },
    //         }));
    //     } catch (error) {

    //     }
    //     return setTimeout(getForecastData, 180000)
    // };

    // const getWebhookData = async () => {

    //     try {
    //         const response = await CallWithAuth('GET', GET_WEBHOOK_DATA);
    //         const { message: data } = response?.res?.data;
    //         setWeatherData((prevState) => ({
    //             ...prevState,
    //             webhookData: {
    //                 temp: data?.temp,
    //                 wind: data?.wind,
    //                 windDirection: data?.windDirection,
    //                 pressure: data?.pressure,
    //                 rain: data?.rain,
    //                 dust: data?.dust,
    //             },
    //         }));
    //     } catch (error) {

    //     }
    //     return setTimeout(getWebhookData, 180000)
    // };

    // const fetchData = async () => {

    //     try {
    //         const response = await CallWithAuthFormData('POST', GET_ASSET_PERFORMANCE, {
    //             userId: auth?.userData?._id,
    //         })
    //         const result = response?.res?.data?.data;
    //         const timeDiffValue = timeDiff(result?.startTime);

    //         setCompleteShiftData(result);

    //         shiftTiming = {
    //             date: new Date(result?.shiftDate),
    //             start: new Date(result?.startTime),
    //             end: new Date(result?.endTime)
    //         }

    //         setShiftInfo({
    //             shiftPlanId: result.shiftPlanId,
    //             reportNo: result.reportNo,
    //             status: result?.status,
    //             type: result?.shift,

    //         })

    //         setFallingBehind({
    //             excavators: result?.excavators
    //                 ?.filter((ex) =>
    //                     getAlert(
    //                         timeDiffValue,
    //                         ex?.productive_hours,
    //                         ex.actualLoads,
    //                         ex.targetLoads
    //                     )
    //                 )
    //                 .map((ex) => ex.excavatorName),
    //             trucks: (() => {
    //                 let res = new Set();
    //                 result?.excavators?.forEach(ex => {
    //                     ex.trucks?.filter((t) =>
    //                         getAlert(
    //                             timeDiffValue,
    //                             t?.productive_hours,
    //                             t.actualLoads,
    //                             t.targetLoads
    //                         )
    //                     ).forEach((t) => res.add(t.truckName))
    //                 })
    //                 return [...res]
    //             })()
    //         })
    //         setBreakdownEquipments({
    //             excavators: result?.excavators
    //                 ?.filter((ex) => ex.breakdown
    //                 )
    //                 .map((ex) => ex.excavatorName),
    //             trucks: (() => {
    //                 let res = new Set();
    //                 result?.excavators?.forEach(ex => {
    //                     ex.trucks?.filter((t) =>
    //                         t.breakdown
    //                     ).forEach((t) => res.add(t.truckName))
    //                 })
    //                 return [...res]
    //             })()
    //         })
    //         setShiftPerformance({
    //             fallingBehind: getAlert(
    //                 timeDiffValue,
    //                 result.startTime,
    //                 result.endTime,
    //                 result.currentBCM.actualLoads,
    //                 result.currentBCM.targetLoads
    //             ),
    //             currentBCM: result.currentBCM,
    //             previousBCM: result.previousBCM,
    //         })

    //         setEquipments({
    //             excavators: result.excavators.map(excavator => excavator?.excavatorName),
    //             trucks: (() => {
    //                 let res = new Set();
    //                 result?.excavators?.forEach(ex => {
    //                     ex.trucks?.forEach((t) => res.add(t.truckName))
    //                 })
    //                 return [...res]
    //             })()
    //         })

    //         setShiftDetail({
    //             excavators: result.excavators
    //         })
    //     } catch (error) {
    //         console.log(error?.message);
    //     }
    //     setLoading(false);
    //     return setTimeout(fetchData, 30000)
    // };

    const fetchDumpCycleTrackerData = async (type) => {
        // try {v
        if (dumpCycleSourceRef.current) {
            dumpCycleSourceRef.current?.cancel()
        }
        dumpCycleSourceRef.current = axios.CancelToken.source();
        const response = await CallWithAuthFormData('POST', GET_DUMP_CYCLE_DATA, { date: dateForDumpTrackerRef.current }, dumpCycleSourceRef.current?.token);
        const result = response?.res?.data?.data;
        if (result && result[0]) {
            const shiftStart = getShiftTime(result[0]?.shiftStartTimeUTC);
            const shiftEnd = getShiftEndTime(result[0]?.shiftEndTimeUTC);
            result[0].excavators = result[0]?.excavators?.map((excavator) => ({
                ...excavator,
                trucks: excavator?.trucks?.map((truck) => ({
                    ...truck,
                    intervals: getLoadsInIntervals(truck.loads, new Date(shiftStart), new Date(shiftEnd), Math.round(parseInt(truck.targetLoads) / 9)),
                })),
            }));
        }
        if (result && result[1]) {

            const shiftStartPrev = getShiftTime(result[1].shiftStartTimeUTC);
            const shiftEndPrev = getShiftEndTime(result[1].shiftEndTimeUTC);

            result[1].excavators = result[1].excavators?.map((excavator) => ({
                ...excavator,
                trucks: excavator?.trucks?.map((truck) => ({
                    ...truck,
                    intervals: getLoadsInIntervals(truck.loads, new Date(shiftStartPrev), new Date(shiftEndPrev), Math.round(parseInt(truck.targetLoads) / 9)),
                })),
            }));
        }

        if (!(response?.res?.data?.fromCache && !response?.res?.data?.data)) {
            setDumpTrackerLoading(false)
        }

        if (response?.res?.status !== 504) {
            if (!response?.res?.data && !response?.res?.status) {
                console.log("ERR_CONNECTION_CLOSED");
            } else {
                setDumpCycleTrackerData(result)
            }
        }

        setLoading(false);
        if (type != 'one')
            return setTimeout(fetchDumpCycleTrackerData, 20000);

    };

    const getShiftTime = (time) => {
        const shiftStart = new Date(time);

        // shiftStart.setMinutes(0);
        return shiftStart;
    };

    const getShiftEndTime = (endTime) => {
        const currentDateTime = new Date();
        const shiftEnd = new Date(endTime);
        // if (shiftEnd.getTime() > currentDateTime.getTime()) {
        //     currentDateTime.setHours(currentDateTime.getHours() + 1);
        //     currentDateTime.setMinutes(0);
        //     return currentDateTime;
        // }
        // shiftEnd.setMinutes(0);
        // shiftEnd.setHours(shiftEnd.getHours() + 1);
        return shiftEnd;
    };

    const getLoadsInIntervals = (loads, shiftStart, shiftEnd, target) => {

        const allIntervals = [];
        let count = 0;
        const loadProperties = [
            'dumpingStartingTime',
            'dumpingEndingTime',
            'sourceArivalTime',
            'excavatorConnectedTime',
            'loadFilledTime',
            'sourceExitTime',
            // 'trammingEmptyTime',
            'sourceReturningTime',
            'dumpPointEntryTime',
            'dumpPointExitTime'
        ];

        const loadInLineProperties = [
            'sourceReturningTime',
            // 'trammingEmptyTime',
            'dumpPointExitTime',
            'dumpingEndingTime',
            'dumpingStartingTime',
            'dumpPointEntryTime',
            'sourceExitTime',
            'loadFilledTime',
            'excavatorConnectedTime',
            'sourceArivalTime',
        ];

        const allLoads = loads?.map((load) => ({ ...load, refTime: load[loadProperties[loadProperties.findIndex(prop => load[prop])]] }));

        allLoads.sort((a, b) => new Date(a.refTime).getTime() - new Date(b.refTime).getTime());
        // console.log(allLoads, "hey");

        while (shiftStart.getTime() < shiftEnd.getTime()) {
            let nextHour = new Date(shiftStart);
            nextHour.setHours(shiftStart.getHours() + 1);
            nextHour.setMinutes(0)
            if (nextHour.getTime() > shiftEnd.getTime())
                nextHour = new Date(shiftEnd)

            const intervalLoads = allLoads.filter(currentLoad =>
                currentLoad &&
                shiftStart.getTime() <= new Date(currentLoad.refTime).getTime() &&
                nextHour.getTime() >= new Date(currentLoad.refTime).getTime()
            ).map(currentLoad => ({
                name: `${++count} Load`,
                ...currentLoad,
                status: 9 - loadInLineProperties.findIndex(prop => currentLoad[prop]),
            }));

            allIntervals.push({
                target: target,
                formatedInterval: `${formatDate(shiftStart)} - ${formatDate(nextHour)}`,
                loads: intervalLoads,
            });

            shiftStart.setHours(shiftStart.getHours() + 1);
            shiftStart.setMinutes(0);

        }
        return allIntervals;
    };

    useEffect(() => {
        setLoading(true)
        // const a = fetchData();
        // const b = getTodayAndTomorrowData();
        // const c = getForecastData();
        // const d = getWebhookData();
        const e = fetchDumpCycleTrackerData();

        return () => {
            // clearTimeout(a);
            // clearTimeout(b);
            // clearTimeout(c);
            // clearTimeout(d);
            clearTimeout(e);
        }
    }, []);

    return (
        <DashboardContext.Provider value={
            {
                // shiftTiming,
                // weatherData,
                // breakdownEquipments,
                // fallingBehind,
                // shiftPerformance,
                // shiftDetail,
                // shiftInfo,
                loading,
                // equipments,
                dumpCycleTrackerData,
                setShiftChoiceForDumpTracker,
                shiftChoiceForDumpTracker,
                setDateForDumpTracker,
                shiftChoiceForDumpTrackerRef,
                dateForDumpTrackerRef,
                dateForDumpTracker,
                setDumpCycleTrackerData,
                dumpTrackerLoading, setDumpTrackerLoading
            }
        }>
            {children}
        </DashboardContext.Provider>
    )
}


export default DashboardContext;