import 'react-toastify/dist/ReactToastify.css';
import './App.css';

import { useEffect, useRef } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet';
import Missing from './components/Missing';
import RequireAuth from './components/RequireAuth';
import Unauthorized from './components/Unauthorized';
import useAuth from './hooks/useAuth';
import AddDailyPlan from './pages/ShiftPlanning/AddDailyPlan';
import DumpPoints from './pages/DumpPoints/DumpPoints';
import ChangePassword from './pages/Login/ChangePassword';
import ForgetPassword from './pages/Login/ForgetPassword';
import LoginWithTemporarayPassword from './pages/Login/LoginWithTemporarayPassword';
import Register from './pages/Login/Register';
import Login from './pages/Login/login';
import ManageUser from './pages/ManageUser/ManageUser';
import MyAccount from './pages/MyAccount';
import ShiftListing from './pages/ShiftListing';
import Test from './pages/Test';
import History from "./pages/History/history";
import AuthLayout from "./components/AuthLayout";
import MaintainancePage from './pages/ApplicatiionDown/Maintainance'
import Maintainance from './pages/Maintenance/Dashboard'
import LandingPage from './pages/LandingPage';
import ComingSoon from './pages/LandingPage/ComingSoon';
import ManagementKPIs from './pages/ManagementKPIs/ManagementKPIs'
import AssetAnalytics from './pages/ManagementKPIs/AssetAnalytics/AssetAnalytics'
import Delays from './pages/ManagementKPIs/Delays/Delays'
import AssetMonitoring from './pages/AssetMonitoring/AssetMonitoring'
import Simulator from './pages/Simulator/Simulator';
import PresStartCheck from './pages/preStartChecks/index.tsx';
import { AddDailyPlanProvider } from './pages/ShiftPlanning/Context/context';
import { ManagementKPIsContextProvider } from './pages/ManagementKPIs/Context/ManagementKPIsContext';
import DigRates from './pages/DigRates/DigRates';
import AssetPerformance from 'pages/Dashboard';
import DelayAndActivity from 'pages/DelayAndActivity/DelayAndActivity';
import ShiftNotes from 'pages/ShiftNotes/ShiftNotes';

const ROLES = {
  // ADMIN: 'Admin',
  SUPERVISOR: 300,
  ACTING_SUPERVISOR: 400,
  MAINTAINANCE_SUPERVISOR: 600,
  SUPER_ADMIN: 500,
  MECHANNIC: 700
};

const App = () => {

  const { logout, maintainanceOngoing, auth } = useAuth();
  let latest_date = new Date();
  function checkInactivity() {
    const now = new Date();
    const elapsed = now - latest_date;
    if (elapsed > 6 * 3600 * 1000) {
      console.log('log out automatically');
      logout();
    }
  }
  const intervalRef = useRef(null);
  const inactivityRef = useRef(null);
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (localStorage.getItem('accessToken')) {
        checkInactivity();
      }
    }, 6000);
    inactivityRef.current?.addEventListener('click', () => {
      latest_date = new Date();
    });
    return () => {
      clearInterval(intervalRef.current);
      inactivityRef.current?.removeEventListener('click', () => {
        latest_date = new Date();
      });
    };
  }, []);


  return (
    <div style={{ backgroundColor: "#FFF" }} ref={inactivityRef}>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Routes>
        {/* open routes */}
        <Route path="/" element={<Login />} />
        <Route element={<RequireAuth allowedRoles={[ROLES.SUPERVISOR, ROLES.ACTING_SUPERVISOR, ROLES.SUPER_ADMIN, ROLES.MAINTAINANCE_SUPERVISOR]} />}>
          <Route path="landing-page" element={maintainanceOngoing ? <MaintainancePage /> : <LandingPage />} />
        </Route>

        {!maintainanceOngoing && <>

          <Route
            path="/loginwithtemporarypassword"
            element={<LoginWithTemporarayPassword />}
          />
          <Route path="/register" element={<Register />} />
          <Route path="/forgetpassword" element={<ForgetPassword />} />
          <Route path="/changepassword" element={<ChangePassword />} />
          <Route path="/unauthorized" element={<Unauthorized />} />

          {/* we want to protect these routes */}
          {process.env.REACT_APP_ENV == 'stage' &&
            <>
              <Route element={<RequireAuth allowedRoles={[ROLES.SUPERVISOR, ROLES.ACTING_SUPERVISOR, ROLES.SUPER_ADMIN]} />}>
                <Route path="simulator" element={<Simulator />} />
              </Route>
              <Route element={<RequireAuth allowedRoles={[ROLES.SUPERVISOR, ROLES.ACTING_SUPERVISOR, ROLES.SUPER_ADMIN]} />}>
                <Route path="/asset-tracking/landing-page" element={<AssetMonitoring />} />
              </Route>

              <Route element={<RequireAuth allowedRoles={[ROLES.SUPERVISOR, ROLES.ACTING_SUPERVISOR, ROLES.SUPER_ADMIN]} />}>
                <Route path="management-kpis">
                  <Route path="asset-analytics" element={<ManagementKPIsContextProvider><AssetAnalytics /></ManagementKPIsContextProvider>} />
                  <Route path="delays" element={<ManagementKPIsContextProvider><Delays /></ManagementKPIsContextProvider>} />
                </Route>
              </Route>
            </>
          }
          <Route element={<RequireAuth allowedRoles={[ROLES.SUPERVISOR, ROLES.ACTING_SUPERVISOR, ROLES.SUPER_ADMIN]} />}>
            <Route path="user-management/landing-page" element={<ManageUser />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.ADMIN, ROLES.SUPERVISOR, ROLES.ACTING_SUPERVISOR, ROLES.SUPER_ADMIN, ROLES.MAINTAINANCE_SUPERVISOR]} />}>
            <Route path="myaccount" element={<MyAccount />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.SUPERVISOR, ROLES.ACTING_SUPERVISOR, ROLES.SUPER_ADMIN]} />}>
            <Route path="tvdashboard" element={<AssetPerformance />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.SUPERVISOR, ROLES.ACTING_SUPERVISOR, ROLES.SUPER_ADMIN]} />}>
            <Route path="shift-management"  >
              <Route path="shift-planning" element={<ShiftListing />} />
              <Route path="dashboard" element={<AssetPerformance />} />
              {/* <Route path="dashboard" element={<Dashboard />} /> */}
              {process.env.REACT_APP_ENV == 'stage' && <Route path="dig-rates" element={<DigRates />} />}
              <Route path="dump-cycle-tracker" element={<Maintainance />} />
              <Route path="history" element={<AuthLayout pageName="History"><History /></AuthLayout>} />
              <Route path="delay-activity" element={<DelayAndActivity />} />
              <Route path="shift-notes" element={<ShiftNotes />} />
            </Route>
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.SUPERVISOR, ROLES.ACTING_SUPERVISOR, ROLES.SUPER_ADMIN]} />}>
            <Route path="coming-soon" element={<ComingSoon />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.SUPERVISOR, ROLES.ACTING_SUPERVISOR, ROLES.SUPER_ADMIN]} />}>
            <Route path="shift-management/shift-planning/add-daily-plan" element={
              <AddDailyPlanProvider>
                <AddDailyPlan />
              </AddDailyPlanProvider>
            } />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.SUPERVISOR, ROLES.ACTING_SUPERVISOR, ROLES.SUPER_ADMIN]} />}>
            <Route path="test" element={<Test />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.SUPERVISOR, ROLES.ACTING_SUPERVISOR, ROLES.SUPER_ADMIN]} />}>
            <Route path="dump-management/landing-page" element={<DumpPoints />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.SUPERVISOR, ROLES.ACTING_SUPERVISOR, ROLES.SUPER_ADMIN]} />}>
            <Route path="pre-start-check/landing-page" element={<PresStartCheck />} />
          </Route>
        </>}
        {/* catch all */}
        <Route path="*" element={<Missing />} />
      </Routes>
    </div>
  );
};

export default App;
