export const dayDefaultHouryData = {
    "06:00": 0,
    "07:00": 0,
    "08:00": 0,
    "09:00": 0,
    "10:00": 0,
    "11:00": 0,
    "12:00": 0,
    "13:00": 0,
    "14:00": 0,
    "15:00": 0,
    "16:00": 0,
    "17:00": 0
}

export const nightDefaultHouryData = {
    "17:00": 0,
    "18:00": 0,
    "19:00": 0,
    "20:00": 0,
    "21:00": 0,
    "22:00": 0,
    "23:00": 0,
    "24:00": 0,
    "01:00": 0,
    "02:00": 0,
    "03:00": 0,
    "04:00": 0,
}