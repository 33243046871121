import { Riple } from "react-loading-indicators";
import ExcavatorCard from "../ExcavatorCard/ExcavatorCard";
import moment from "moment";
import { ShiftStatusEnum } from "pages/Dashboard/constant";

const ExcavatorsCards = ({ isLoading, excavatorData, summaryData }) => {

  if (isLoading)
    return <div style={{ height: '100%' }} className="d-flex align-items-center justify-content-center">
      <Riple color="#317bcc" size="medium" text="" textColor="" />
    </div>
  if (!excavatorData || excavatorData?.length === 0) {
    return <div
      style={{ color: '#99A0AC', flexGrow: 1 }}
      className='d-flex px-5 w-100 align-items-center justify-content-center'>
      <span className="mb-3">No records to display</span>
    </div>
  }

  const calculatedAverage = (ex) => {
    if (summaryData?.shiftStatus === ShiftStatusEnum.PUBLISHED) {
      return 0;
    }

    const start = moment(summaryData?.shiftStartTime).tz("Pacific/Auckland");
    let end = moment().tz("Pacific/Auckland");
    if (summaryData?.shiftStatus === ShiftStatusEnum.ENDED) {
      // eslint-disable-next-line no-const-assign
      end = moment(summaryData?.shiftEndTime).tz("Pacific/Auckland")
    }
    // Calculate the difference in hours
    const timeElapsed = end.diff(start, "hours", true);
    return Math.round(ex?.actualBCMs / timeElapsed);
  };

  return (

    <div style={{ gap: 24 }} className="d-flex flex-wrap px-5 ">
      {excavatorData.map((ex, index) => (
        <ExcavatorCard
          key={index}
          index={index}
          excavatorName={ex?.excavatorName}
          operatorName={ex?.operatorName}
          actualBCM={ex?.actualBCMs}
          targetBCM={ex?.targetBCMs}
          average={calculatedAverage(ex)}
          status={ex?.status}
          truckAssignments={ex?.truckAssignments}
          productiveHours={ex?.productiveHours}
          shiftEndTime={ex?.shiftEndTime}
          hourlyData={ex?.hourlyData}
        />
      ))}
    </div>
  )
};

export default ExcavatorsCards;
