import React from 'react';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import TextInputField from '../../../components/GeneralFields/TextInputField';
import OperatorSelect from '../../../components/OperatorSelect/OperatorSelect';
import moment from 'moment';
import { DateTimeFormats } from '../../../utils/contants';
import { getDurationBetweenMomentsInHHMM } from '../../../utils/helper';
import DynamicSelectField from '../../../components/DynamicSelectField/DynamicSelectField';
import TextAreaField from '../../../components/GeneralFields/TextAreaField';
import DateTimeSelect from '../../../components/GeneralFields/DateTimeSelect/DateTimeSelect';
import { useAddDailyPlan } from '../../../Context/context';

const ShiftPlanFields = ({ formData, isMandateErrorOpen, setIsMandateErrorOpen, viewOnly, handleFieldChange, showError,
    isHaulTrucksSelectionDisabled, materialSelected
}) => {
    const {
        materialList,
        areaList,
        sourceDumpList,
        activityDescriptions,
        isExcavatorShiftDetailsFlyerOpen,
        assignedExcavatorDetailsFormData,
        generalShiftPlanDetailsFormData
    } = useAddDailyPlan()
    return (
        <div>
            {isMandateErrorOpen && !viewOnly && <ErrorMessage message={"Please make sure all the required fields marked with an asterisk are filled correctly before you save."} onClose={() => setIsMandateErrorOpen(false)} />}

            <TextInputField
                label="Plant ID"
                required={true}
                isDisabled={true}
                placeholder="EX000"
                value={formData.plantId}
                onChange={(v) => handleFieldChange('plantId', v)}
                viewOnly={viewOnly}
            />

            <TextInputField
                label="Category"
                required={true}
                isDisabled={true}
                placeholder="Select Category"
                value={formData.category}
                onChange={(v) => handleFieldChange('category', v)}
                viewOnly={viewOnly}
            />

            <OperatorSelect
                label="Operator"
                required={true}
                isMulti={false}
                equipmentType={"Excavator"}
                value={formData.operator}
                userTypes={[200]}
                initialCrew={generalShiftPlanDetailsFormData?.crew?.label}
                onChange={(v) => handleFieldChange('operator', v)}
                viewOnly={viewOnly}
                error={showError && !formData?.operator}
                errorMessage={"Please select a Operator"}
            />

            <OperatorSelect
                label="Assistant Operator"
                required={false}
                isMulti={false}
                placeholder={"-"}
                userTypes={[200]}
                initialCrew={generalShiftPlanDetailsFormData?.crew?.label}
                clearSelection={true}
                value={formData.assistantOperator}
                onChange={(v) => handleFieldChange('assistantOperator', v)}
                viewOnly={viewOnly}
            />

            <OperatorSelect
                label="Trainee"
                required={false}
                placeholder={"-"}
                isMulti={false}
                clearSelection={true}
                initialCrew={generalShiftPlanDetailsFormData?.crew?.label}
                userTypes={[100]}
                value={formData.trainee}
                onChange={(v) => handleFieldChange('trainee', v)}
                viewOnly={viewOnly}
            />

            <DynamicSelectField
                label="Material"
                required={true}
                isDisabled={materialSelected}
                options={materialList?.map((material) => ({ label: material.MaterialType, value: material._id }))}
                placeholder="Select Material"
                value={formData.material}
                onChange={(v) => handleFieldChange('material', v)}
                viewOnly={viewOnly}
                error={showError && !formData?.material}
                errorMessage={"Please select a Material"}
            />

            <DynamicSelectField
                options={areaList?.map((area) => ({ label: area.location, value: area._id }))}
                label="Source Area"
                required={true}
                placeholder="Select Source Area"
                value={formData.sourceArea}
                isSearchable={true}
                onChange={(v) => handleFieldChange('sourceArea', v)}
                viewOnly={viewOnly}
                error={showError && !formData?.sourceArea}
                errorMessage={"Please select a Source Area"}
            />

            <DynamicSelectField
                options={sourceDumpList?.map((dump) => ({ label: dump.name, value: dump._id }))}
                label="Dump Area"
                required={true}
                isSearchable={true}
                isMulti={true}
                placeholder="Select Dump Area"
                value={formData.dumpArea}
                onChange={(v) => handleFieldChange('dumpArea', v)}
                viewOnly={viewOnly}
                error={showError && !formData?.dumpArea?.length}
                errorMessage={"Please select a dump Area"}
            />

            <DynamicSelectField
                label="Haul Trucks"
                required={true}
                isMulti={true}
                placeholder="Select Haul Trucks"
                options={assignedExcavatorDetailsFormData[isExcavatorShiftDetailsFlyerOpen]?.excavatorHaulTrucks?.map((v) => ({ value: v.value, label: v.label }))}
                value={formData?.haulTrucks || "-"}
                viewOnly={viewOnly}
                isDisabled={isHaulTrucksSelectionDisabled}
                onChange={(v) => handleFieldChange('haulTrucks', v)}
            />

            <TextInputField
                isDisabled={true}
                label="Target BCMs"
                required={true}
                placeholder="Select Target BCMs"
                value={formData.targetBCMs}
                viewOnly={viewOnly}
            />

            <DateTimeSelect
                required
                minDate={new Date(moment(generalShiftPlanDetailsFormData?.startTime, DateTimeFormats.DATE_TIME_U).toISOString())}
                minTime={
                    moment(generalShiftPlanDetailsFormData?.startTime, DateTimeFormats.DATE_TIME_U).format('YYYY-MM-DD') !==
                        moment(formData.startTime, DateTimeFormats.DATE_TIME_U).format('YYYY-MM-DD')
                        ? new Date(moment().startOf('day').toISOString())
                        : new Date(moment(generalShiftPlanDetailsFormData?.startTime, DateTimeFormats.DATE_TIME_U).toISOString())}

                maxDate={new Date(moment(formData.endTime, DateTimeFormats.DATE_TIME_U).subtract(15, 'minutes').toISOString())}
                maxTime={
                    moment(formData.startTime, DateTimeFormats.DATE_TIME_U).format('YYYY-MM-DD') !==
                        moment(formData.endTime, DateTimeFormats.DATE_TIME_U).format('YYYY-MM-DD')
                        ? new Date(moment().endOf('day').toISOString())
                        : new Date(moment(formData.endTime, DateTimeFormats.DATE_TIME_U).subtract(15, 'minutes').toISOString())
                }
                viewOnly={viewOnly}
                label="Shift Start"
                value={new Date(moment(formData.startTime, DateTimeFormats.DATE_TIME_U).toISOString())}
                onChange={(v) => {
                    const newStartTime = moment(v).format(DateTimeFormats.DATE_TIME_U);
                    handleFieldChange('startTime', newStartTime);

                }}
                error={showError && !formData?.startTime}
                errorMessage="Please select shift plan start time"
            />

            <DateTimeSelect
                required
                minDate={new Date(Math.max(
                    moment(formData.startTime, DateTimeFormats.DATE_TIME_U).add(10, 'minutes').valueOf(),
                    Date.now()
                ))}
                minTime={
                    moment(
                        Math.max(
                            moment(formData.startTime, DateTimeFormats.DATE_TIME_U).add(10, 'minutes').valueOf(),
                            Date.now() + 600000
                        )
                    ).format('YYYY-MM-DD') !== moment(formData.endTime, DateTimeFormats.DATE_TIME_U).format('YYYY-MM-DD')
                        ? moment().startOf('day').valueOf()
                        : Math.max(
                            moment(formData.startTime, DateTimeFormats.DATE_TIME_U).add(10, 'minutes').valueOf(),
                            Date.now() + 600000
                        )
                }
                maxTime={
                    moment(generalShiftPlanDetailsFormData?.endTime, DateTimeFormats.DATE_TIME_U).format('YYYY-MM-DD') !==
                        moment(formData.endTime, DateTimeFormats.DATE_TIME_U).format('YYYY-MM-DD')
                        ? new Date(moment().endOf('day').toISOString())
                        : new Date(moment(generalShiftPlanDetailsFormData?.endTime, DateTimeFormats.DATE_TIME_U).toISOString())}
                maxDate={new Date(moment(generalShiftPlanDetailsFormData?.endTime, DateTimeFormats.DATE_TIME_U).toISOString())}
                label="Shift End"
                viewOnly={viewOnly}
                value={new Date(moment(formData.endTime, DateTimeFormats.DATE_TIME_U).toISOString())}
                onChange={(v) => {
                    const newEndTime = moment(v).format(DateTimeFormats.DATE_TIME_U);
                    handleFieldChange('endTime', newEndTime);
                }}
                error={showError && !formData?.endTime}
                errorMessage="Please select shift Plan end time"
            />

            <TextInputField
                label="Shift Duration"
                placeholder={"00:00 hrs"}
                required={true}
                isDisabled={true}
                value={getDurationBetweenMomentsInHHMM(moment(formData.startTime, DateTimeFormats.DATE_TIME_U), moment(formData.endTime, DateTimeFormats.DATE_TIME_U))}
                viewOnly={viewOnly}
            />

            <DynamicSelectField
                required
                label="Productive Hours"
                value={formData.productiveHours}
                options={Array.from({ length: moment(formData.endTime, DateTimeFormats.DATE_TIME_U).diff(moment(formData.startTime, DateTimeFormats.DATE_TIME_U), 'hours') }, (_, index) => ({ value: index + 1, label: (index + 1).toString() }))}
                error={showError && (!formData?.productiveHours || formData?.productiveHours?.value > moment(formData.endTime, DateTimeFormats.DATE_TIME_U).diff(moment(formData.startTime, DateTimeFormats.DATE_TIME_U), 'hours'))}
                errorMessage={!formData?.productiveHours ? "Please select productive hours" : "Productive Hours are greater than duration"}
                onChange={(v) => {
                    handleFieldChange('productiveHours', v)
                    handleFieldChange('targetBCMs', (formData?.actualBCMs ?? 0) + (v.value * formData?.bcmPerHour))
                }}
                viewOnly={viewOnly}
            />

            <DynamicSelectField
                options={activityDescriptions?.filter(ad => ad.equipType == "Excavator")?.map((ad) => ({ value: ad._id, label: ad.activityDescription }))}
                label="Activity Description"
                required={true}
                isSearchable={true}
                placeholder="Select Activity Description"
                value={formData.activityDescription}
                onChange={(v) => handleFieldChange('activityDescription', v)}
                viewOnly={viewOnly}
                error={showError && !formData?.activityDescription}
                errorMessage={"Please select Activity Description"}
            />

            <TextAreaField
                label={"Known Hazards"}
                placeholder={"-"}
                style={{ height: 75 }}
                value={formData.knownHazards}
                onChange={(v) => handleFieldChange('knownHazards', v)}
                viewOnly={viewOnly}
            />

            <TextAreaField
                label={"Start of Shift Note to Operator"}
                style={{ height: 75 }}
                placeholder={"-"}
                value={formData.startOfShiftNoteToOperator}
                onChange={(v) => handleFieldChange('startOfShiftNoteToOperator', v)}
                viewOnly={viewOnly}
            />
        </div>
    )
}

export default ShiftPlanFields